import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { NetworkService } from './services/network.service';
import { GeolocationService } from './services/geolocation.service';
import { ToastService } from './services/toast.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Browser } from "@capacitor/browser";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  isDesktop = false;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  constructor(
      private zone: NgZone,
      private platform: Platform,
      private networkService: NetworkService,
      private router: Router,
      private toastService: ToastService,
      private geolocationService: GeolocationService
  ) {
    this.listenForDeepLinkRedirection();
    this.platform.ready().then(() => {
      this.initializeApp();
    });
  }

  initializeApp() {
    StatusBar.setStyle({style: Style.Default});
    SplashScreen.hide();

    this.geolocationService.watchPosition();

    this.networkService.startListeningOnConnection();

    this.platform.backButton.subscribe( () => {
      if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
        navigator['app'].exitApp();
      } else {
        this.toastService.presentToast('Press back again to exit');
        this.lastTimeBackPress = new Date().getTime();
      }
    });
  }

  public listenForDeepLinkRedirection()
  {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {

        // Example:
        // slugWithQueryParams: https://business.bepark.eu/tabs/home?page=1&search=lorem
        // queryParams = page=1&search=lorem
        // slug = /tabs/home
        const slugWithQueryParams = event.url.split(".eu").pop().split('?')
        const queryParams = slugWithQueryParams[1];
        const slug = slugWithQueryParams.shift();
        const partialSlug = slug.split('/')

        let timestamp = Date.now();

        if (slug) {

          let url;
          if(partialSlug[1] === 'reset-password' ) {
            Browser.open({ url: event.url + '?time='+ timestamp, presentationStyle : 'popover' });
          } else {
            switch (slug) {
              case '/authentication/mobile-redirect':
              case 'com.bepark.besaas://login':
                url = `/login?${queryParams}&time=${timestamp}`;
                this.router.navigateByUrl(url);
                break;
              case '/reset-my-password':
                url = `/login?reset-password=1&time=${timestamp}`;
                this.router.navigateByUrl(url);
                break;
              case '/profiles/me/show':
                url = `/profile&time=${timestamp}`;
                this.router.navigateByUrl(url);
                break;
              default:
                url = null
                break;
            }

            if(url !== null) {
              Browser.close(); // Forced closing of the iframe to avoid blocking the user (Mandatory on IOS)
              url = null;
            }
          }
        }
      });
    });
  }
}
